@media (min-width: 1000px) {
  
  ::-webkit-scrollbar {
    width: 1vw;
  }
  ::placeholder { /* Firefox, Chrome, Opera */
    color: #DFC1AB;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
    background-color:rgba(255, 255, 255, 0) ;
    border: #FFE7BA 0.2vw solid;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    background: #FFE7BA;
    border-radius: 10px;
    border:3.5px rgba(255, 255, 255, 0) solid;
    background-clip: content-box;
    width: 110px;
  }
}
* {
  box-sizing: border-box;
}
@media (max-width: 1000px){
  ::-webkit-scrollbar {
    width: 1.3vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    border: #FFE7BA 0.3vw solid;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    background: #FFE7BA;
    border-radius: 10px;
    border:3.5px rgba(255, 255, 255, 0) solid;
    background-clip: content-box;
    width: 1.4vw;
  }
}
@font-face {
  font-family: futura;
  src: url(./fonts/Futura\ Book\ font.ttf);
}
@font-face {
  font-family: Source Sans Pro;
  src: url(./fonts/SourceSansPro-Regular.ttf);
}
@font-face {
  font-family: Source Sans Pro Light;
  src: url(SourceSansPro-Light.ttf);
}
@font-face {
  font-family: Bungee Inline;
  src: url(./fonts/BungeeInline-Regular.ttf);
}
@font-face {
  font-family: Pica;
  src: url(./fonts/Pica.ttf);
}
html,
body,
#root {
  scrollbar-width: thin;
  overflow: hidden;
  position: 'absolute';
  background-color: black;
  /* font-weight: 500; */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color:'#36AA9D';
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
/* @media (max-height: 400px) {
  html,body,#root {height:'120%'}
}   */
svg:hover{
  cursor: pointer;
  
}
button:hover{
  cursor: pointer;

}
svg:active{
  background-color: none;
}
canvas{
    position:'absolute';
    top:0;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
canvas:active{
  cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}